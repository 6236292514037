<template>
  <b-modal
    id="temporary-residence-modal"
    ref="refModal"
    title="Mẫu CT01 - Tờ khai thay đổi thông tin cư trú"
    size="lg"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-esc
    scrollable
    no-close-on-backdrop
    @ok="onSubmit"
    @show="onOpen"
    @hidden="resetModal"
  >
    <div v-if="itemLocal.tenantId > 0">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="registrationAuthority"
                rules="required"
              >
                <b-form-group
                  label="Cơ quan đăng ký cư trú."
                >
                  <template
                    v-slot:label
                  >
                    Cơ quan đăng ký cư trú.
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      v-model="itemLocal.authority"
                      placeholder="Cơ quan đăng ký cư trú."
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Họ, chữ đệm và tên"
                >
                  <template
                    v-slot:label
                  >
                    Họ, chữ đệm và tên
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="name"
                      v-model="itemLocal.name"
                      placeholder="Họ, chữ đệm và tên"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="dateOfBirth"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.birthday"
                  required
                  :default-value="itemLocal.birthday"
                  label="Ngày, tháng, năm sinh"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">

              <select-gender
                v-model="itemLocal.gender"
                :default-value="itemLocal.gender"
                label="Giới tính"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="idNumber"
                rules="required"
              >
                <b-form-group
                  label="Số định danh cá nhân/CMND"
                >
                  <template
                    v-slot:label
                  >
                    Số định danh cá nhân/CMND
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="idNumber"
                      v-model="itemLocal.idNumber"
                      placeholder="Số định danh cá nhân/CMND"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required"
              >
                <b-form-group
                  label="Số điện thoại liên hệ"
                >
                  <template
                    v-slot:label
                  >
                    Số điện thoại liên hệ:
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="phone"
                      v-model="itemLocal.phone"
                      placeholder="Số điện thoại liên hệ"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="email"
                rules=""
              >
                <b-form-group
                  label="Email"
                >
                  <template
                    v-slot:label
                  >
                    Email
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="email"
                      v-model="itemLocal.email"
                      placeholder="Email"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="address"
                rules="required"
              >
                <b-form-group
                  label="Nơi thường trú"
                >
                  <template
                    v-slot:label
                  >
                    Nơi thường trú:
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="address"
                      v-model="itemLocal.address"
                      placeholder="Nơi thường trú"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="temporaryAddress"
                rules=""
              >
                <b-form-group
                  label="Nơi tạm trú"
                >
                  <template
                    v-slot:label
                  >
                    Nơi tạm trú
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="temporaryAddress"
                      v-model="itemLocal.temporaryAddress"
                      placeholder="Nơi tạm trú"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="currentAddress"
                rules="required"
              >
                <b-form-group
                  label=" Nơi ở hiện tại"
                >
                  <template
                    v-slot:label
                  >
                    Nơi ở hiện tại:
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="currentAddress"
                      v-model="itemLocal.currentAddress"
                      placeholder=" Nơi ở hiện tại"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="job"
                rules=""
              >
                <b-form-group
                  label="Nghề nghiệp, nơi làm việc"
                >
                  <template
                    v-slot:label
                  >
                    Nghề nghiệp, nơi làm việc:
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="job"
                      v-model="itemLocal.job"
                      placeholder="Nghề nghiệp, nơi làm việc"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- 1q312 -->
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="hostName"
                rules="required"
              >
                <b-form-group
                  label="Họ, chữ đệm và tên chủ hộ"
                >
                  <template
                    v-slot:label
                  >
                    Họ, chữ đệm và tên chủ hộ:
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="hostName"
                      v-model="itemLocal.hostName"
                      placeholder="Họ, chữ đệm và tên chủ hộ"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="hostRelation"
                rules="required"
              >
                <b-form-group
                  label=" Quan hệ với chủ hộ"
                >
                  <template
                    v-slot:label
                  >
                    Quan hệ với chủ hộ:
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="hostRelation"
                      v-model="itemLocal.hostRelation"
                      placeholder=" Quan hệ với chủ hộ"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="hostIdNumber"
                rules="required"
              >
                <b-form-group
                  label="Số định danh cá nhân/CMND của chủ hộ"
                >
                  <template
                    v-slot:label
                  >
                    Số định danh cá nhân/CMND của chủ hộ:
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="hostIdNumber"
                      v-model="itemLocal.hostIdNumber"
                      placeholder="Số định danh cá nhân/CMND của chủ hộ"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="note"
                rules=""
              >
                <b-form-group
                  label="Nội dung đề nghị"
                >
                  <template
                    v-slot:label
                  >
                    Nội dung đề nghị:
                  </template>
                  <b-input-group>
                    <b-form-textarea
                      id="note"
                      v-model="itemLocal.note"
                      placeholder="Nội dung đề nghị"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <tenant-info-table
            v-model="itemLocal.members"
            label="Những thành viên trong hộ gia đình"
            :rows="itemLocal.members"
          />
        </b-form>
      </validation-observer>

    </div>
    <vue-html2pdf
      ref="refPrint"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1000"
      filename="CT01"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
    >
      <ContentToPrint
        slot="pdf-content"
        :data="itemLocal"
      />
    </vue-html2pdf>

  </b-modal>
</template>

<script>
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from '@validations';
import SelectDate from "@/views/components/SelectDate.vue";
import VueHtml2pdf from "vue-html2pdf";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BForm,
} from "bootstrap-vue";
import TenantInfoTable from "./TenantInfoTable.vue";
import ContentToPrint from "./ContentToPrint.vue";
import useTemporaryResidence from "./useTemporaryResidenceModal";
import SelectGender from "../../../components/SelectGender.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    SelectDate,
    TenantInfoTable,
    VueHtml2pdf,
    ContentToPrint,
    SelectGender,
  },
  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    // async onSubmit(bvModalEvt) {
    //   bvModalEvt.preventDefault();
    //   const success = await this.refFormObserver.validate();
    //   // eslint-disable-next-line no-useless-return
    //   if (success) {
    //     this.$refs.html2Pdf.generatePdf();
    //   }
    // },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      onOpen,
      refModal,
      refPrint,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useTemporaryResidence(props, emit, refFormObserver);

    return {
      onOpen,
      refModal,
      refPrint,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
