<template>
  <b-modal
    id="modal-preview-pdf-ct01"
    ref="refModal"
    :title="t('Mẫu CT01')"
    ok-title="Đóng"
    ok-only
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="false"
    scrollable

    @show="fetchPdfPreviewUrl"
    @hidden="resetModal"
  >
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>
    <iframe
      v-if="pdfUrl && !isLoading"
      :src="pdfUrl"
      style="width: 100%; height: 80vh; border: none;"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import usePdfPreviewModal from './usePdfPreviewModal';

export default {
  components: {
    BModal,
    BSpinner,

  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      fetchPdfPreviewUrl,
      pdfUrl,
      resetItemLocal,
      resetModal,
      isLoading,
      t,
    } = usePdfPreviewModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      fetchPdfPreviewUrl,
      isLoading,
      pdfUrl,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
