import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import tenantStoreModule from '../tenantStoreModule';

export default function useTemporaryResidence(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'tenant';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tenantStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refPrint = ref(null);
  const refForm = ref(refFormObserver);
  const blankItem = {

    authority: '',
    name: '',
    phone: '',
    email: '',
    birthday: null,
    gender: null,
    idNumber: '',

    address: '',
    temporaryAddress: '',
    currentAddress: '',
    job: '',
    hostName: '',
    hostRelation: '',
    hostIdNumber: '',
    members: [],

  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchData = () => {
    store
      .dispatch('tenant/getResidenceInformation', props.tenant.id)
      .then(response => {
        itemLocal.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onOpen = () => {
    resetItemLocal();
    fetchData();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      tenantId: props.tenant.id,
      authority: itemLocal.value.authority,
      name: itemLocal.value.name,
      idNumber: itemLocal.value.idNumber,
      phone: itemLocal.value.phone,
      email: itemLocal.value.email,
      address: itemLocal.value.address,
      temporaryAddress: itemLocal.value.temporaryAddress,
      currentAddress: itemLocal.value.currentAddress,
      job: itemLocal.value.job,
      hostName: itemLocal.value.hostName,
      hostRelation: itemLocal.value.hostRelation,
      hostIdNumber: itemLocal.value.hostIdNumber,
      note: itemLocal.value.note,
    };

    if (itemLocal.value.gender) {
      data.gender = itemLocal.value.gender.id;
    }
    data.birthday = parseDateToString(itemLocal.value.birthday);

    if (itemLocal.value.members.length > 0) {
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax
      itemLocal.value.members.forEach((member, index) => {
        arr.push({
          id: index + 1,
          name: member.name,
          birthday: member.birthday,
          gender: member.gender,
          job: member.job,
          relation: member.relation,
          idNumber: member.idNumber,
        });
      });

      data.members = JSON.stringify(arr);
    }

    store
      .dispatch('tenant/updateResidenceInformation', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModal.value.toggle('#toggle-btn');
        // refPrint.value.generatePdf();
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        // refPrint.value.generatePdf();
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
      });
  };

  return {
    refModal,
    refForm,
    refPrint,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
    fetchData,
    onOpen,
  };
}
