<template>
  <div class="tenant-list-container">
    <b-row
      v-if="analytic"
      class="match-height"
    >

      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Tất cả')"
          :value="analytic.total"
          icon="UsersIcon"
          color="secondary"
        />
      </b-col>

      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Cá nhân')"
          :value="analytic.individual"
          icon="UsersIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Tổ chức')"
          :value="analytic.organization"
          icon="UsersIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Khách nước ngoài')"
          :value="analytic.foreign"
          icon="UsersIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Khách hàng') }}</b-card-title>
        <crud-buttons
          :name="t('Khách hàng')"
          :selected-rows="selectedRows"
          modal="modal-tenant"
          :show-import="$can('create', 'tenant')"
          :show-export="true"
          link-template-file="https://cdn.resident.vn/attachments/bang_nhap_lieu_cu_dan.xlsx"
          :enable-add="$can('create', 'tenant')"
          :hide-delete="!$can('delete', 'tenant')"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col :md="resolveColWidthIfDisableBed()">
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col
            :md="resolveColWidthIfDisableBed()"
          ><select-room
            v-model="room"
            :apartment="apartment"
          /></b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
            :md="resolveColWidthIfDisableBed()"
          >
            <select-bed
              v-model="bed"
              :room="room"
            />
          </b-col>
          <b-col :md="resolveColWidthIfDisableBed()">
            <select-tenant-status
              v-model="status"
              :default-value="status"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <select-foreign-filter v-model="foreign" />
          </b-col>
          <b-col md="3">
            <select-individual v-model="individual" />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              :placeholder="t('Tìm kiếm')"
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-tenant-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'name'">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="props.row.avatar"
                      />
                    </template>
                    <span class="font-weight-bolder">{{ props.row.name }}</span>
                    <small
                      v-if="props.row.email && props.row.email.length > 0"
                      class="text-muted"
                    ><br>{{ props.row.email }}</small>
                    <small
                      v-if="props.row.apartment"
                      class=""
                    ><br>{{ props.row.apartment.name }} <span v-if="props.row.room">- {{ props.row.room.name }}</span> <span v-if="props.row.bed">- {{ props.row.bed.name }}</span></small>
                    <!-- <span class="font-weight-bold d-block text-nowrap">
                      {{ data.item.client.name }}
                    </span>
                    <small class="text-muted">{{ data.item.client.companyEmail }}</small> -->
                  </b-media>

                </span>

                <span v-else-if="props.column.field === 'birthday'">
                  {{ parseDateToString(props.row.birthday) }}

                </span>
                <span v-else-if="props.column.field === 'installApp'">
                  <b-badge
                    class="font-small-1"
                    :variant="`light-${props.row.userId ? 'primary' : 'danger'}`"
                  >{{ props.row.userId ? "Đã đăng ký" : "Chưa đăng ký" }}</b-badge>

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Khách hàng')"
                    modal="modal-tenant"
                    :hide-delete="!$can('delete', 'tenant')"
                    :hide-edit="!$can('update', 'tenant')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  >
                    <template
                      v-if="$can('update', 'tenant')"
                      slot="other-buttons"
                    >
                      <b-button
                        v-if="!isVmarket"
                        v-b-tooltip.hover.v-success
                        v-b-modal.temporary-residence-modal
                        variant="success"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Bản khai nhân khẩu')"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào! Nếu bạn tìm kiếm khách thuê không thấy, hãy kiểm tra bộ lọc. Trong nhiều TH bạn đang lọc khách đang ở nên không tìm thấy') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Modal -->
    <tenant-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <!-- Modal -->
    <temporary-residence-modal :tenant="item" />
    <tenant-detail-modal :tenant-id="item.id" />
    <delete-tenant-modal
      ref="delete-tenant"
      :items="deleteItems"
      @refetch-data="fetchData"
    />

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BButton,
  VBModal,
  VBTooltip,
  BMedia,
  BAvatar,
  BBadge,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectTenantStatus from '@/views/components/SelectTenantStatus.vue';
import SelectForeignFilter from '@/views/components/SelectForeignFilter.vue';
import SelectIndividual from '@/views/components/SelectIndividual.vue';
import { VueGoodTable } from 'vue-good-table';
import CrudButtons from '@/views/components/CrudButtons.vue';
import TableActions from '@/views/components/TableActions.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';
import TemporaryResidenceModal from '../temporaryResidence/TemporaryResidenceModal.vue';
import TenantDetailModal from '../detail/TenantDetailModal.vue';
import useTenantList from './useTenantList';
import TenantModal from '../modal/TenantModal.vue';
import DeleteTenantModal from '../delete/DeleteTenantModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectTenantStatus,
    SelectForeignFilter,
    SelectIndividual,
    VueGoodTable,
    TableActions,
    CrudButtons,
    TenantModal,
    CardStatistic,
    TemporaryResidenceModal,
    TenantDetailModal,
    DeleteTenantModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    "b-modal": VBModal,
  },
  setup() {
    const {
      analytic,
      item,
      deleteItems,
      printItem,
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      foreign,
      individual,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      isVmarket,
      fetchData,
      fetchTenants,
      deleteTenants,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onPrint,
      refPrint,
      importData,
      exportData,
      onDeleteTenant,
      resolveColWidthIfDisableBed,
      t,
    } = useTenantList();

    return {
      analytic,
      item,
      deleteItems,
      printItem,
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      foreign,
      individual,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      isVmarket,
      fetchData,
      fetchTenants,
      deleteTenants,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onPrint,
      refPrint,
      importData,
      exportData,
      onDeleteTenant,
      parseDateToString,
      resolveColWidthIfDisableBed,
      t,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(tenant) {
      // const deleteObjects = tenant && tenant.id > 0 ? [tenant] : this.selectedRows;
      // this.deleteTenants(deleteObjects);
      this.onDeleteTenant(tenant);

      this.$refs['delete-tenant'].$refs.refModalDeleteTenant.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.tenant-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
              min-width: 100px !important;
            }

            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(7) {
               min-width: 150px !important;
            }
            &:nth-child(8) {
               min-width: 240px !important;
            }
          }
        }
      }
    }
}
</style>
