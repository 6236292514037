<template>
  <div class="text-dark bg-white wrapper position-relative">
    <h5 class="font-weight-bolder text-center">
      CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
    </h5>
    <h5 class="text-center">
      Độc lập – Tự do – Hạnh phúc
    </h5>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="temp">
      {{ `Mẫu CT01 ban hành\ntheo TT số  &#12644;/2021/\nTT-BCA ngày\n 15/5/2021` }}
    </p>
    <h5 class="text-center font-weight-bolder pt-5">
      TỜ KHAI THAY ĐỔI THÔNG TIN CƯ TRÚ
    </h5>
    <p class="ml-3">
      Kính gửi<sup> (1) </sup>: {{ data.authority || '' }}
    </p>
    <p>
      1. Họ, chữ đệm và tên: {{ data.name || '' }}
    </p>
    <b-row class="mb-1">
      <b-col col-6>
        2. Ngày, tháng, năm sinh: {{ parseDateToString(data.birthday) || '' }}
      </b-col>
      <b-col
        v-if="data.gender"
        col-6
      >
        3. Giới tính: {{ data.gender ? data.gender.name : '' }}
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col class="d-flex align-items-center">
        <span class="mr-3">4. Số định danh cá nhân/CMND: </span>
        <p
          v-for="(n, i) in (data.idNumber || '').toString().replace(/\s/g, '')"
          :key="i"
          class="box mb-0"
        >
          {{ n }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col col-6>
        5. Số điện thoại liên hệ: {{ data.phone || '' }}
      </b-col>
      <b-col col-6>
        6. Email: {{ data.email || '' }}
      </b-col>
    </b-row>
    <p>
      7. Nơi thường trú: {{ data.address || '' }}
    </p>
    <p>
      8. Nơi tạm trú: {{ data.temporaryAddress || '' }}
    </p>
    <p>
      9. Nơi ở hiện tại: {{ data.currentAddress || '' }}
    </p>
    <p>
      10. Nghề nghiệp, nơi làm việc: {{ data.job || '' }}
    </p>
    <b-row class="mb-1">
      <b-col col-6>
        11. Họ, chữ đệm và tên chủ hộ: {{ data.hostName || '' }}
      </b-col>
      <b-col col-6>
        12. Quan hệ với chủ hộ: {{ data.hostRelation || '' }}
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col class="d-flex align-items-center">
        <span class="mr-3">13. Số định danh cá nhân/CMND của chủ hộ: </span>
        <p
          v-for="(n, i) in (data.hostIdNumber || '').toString().replace(/\s/g, '')"
          :key="i"
          class="box mb-0"
        >
          {{ n }}
        </p>
      </b-col>
    </b-row>
    <p>
      14. Nội dung đề nghị<sup> (2) </sup>: {{ data.note || '' }}
    </p>
    <p>
      15. Những thành viên trong hộ gia đình cùng thay đổi:
    </p>
    <table v-if="data.members">
      <tr>
        <th>TT</th>
        <th>Họ, chữ đệm và tên</th>
        <th>Ngày, tháng, năm sinh</th>
        <th>Giới tính</th>
        <th>Số định danh cá nhân/CMND</th>
        <th>Nghề nghiệp, nơi làm việc</th>
        <th>Quan hệ với chủ hộ</th>
      </tr>
      <tr
        v-for="(tenant,index) in data.members"

        :key="index"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ tenant.name }}</td>
        <td>{{ tenant.birthday }}</td>
        <td>{{ tenant.gender }}</td>
        <td>{{ tenant.idNumber }}</td>
        <td>{{ tenant.job }}</td>
        <td>{{ tenant.relation }}</td>
      </tr>
    </table>
    <b-row class="d-flex mt-2">
      <b-col class="sign-box">
        <i>..., ngày ...tháng ...năm ...</i>
        <p>Ý KIẾN CỦA CHỦ HỘ<sup> (3) </sup></p>
        <i>(Ghi rõ nội dung và ký, ghi rõ họ tên)</i>
      </b-col>
      <b-col class="sign-box">
        <i>..., ngày ...tháng ...năm ...</i>
        <p>Ý KIẾN CỦA CHỦ SỞ HỮU HOẶC NGƯỜI ĐẠI DIỆN CHỖ Ở HỢP PHÁP<sup> (3) </sup></p>
        <i>(Ký, ghi rõ họ tên)</i>
      </b-col>
      <b-col class="sign-box">
        <i>..., ngày ...tháng ...năm ...</i>
        <p>Ý KIẾN CỦA CHA, MẸ HOẶC NGƯỜI GIÁM HỘ <sup> (4) </sup></p>
        <i>(Ký, ghi rõ họ tên)</i>
      </b-col>
      <b-col class="sign-box">
        <i>..., ngày ...tháng ...năm ...</i>
        <p>NGƯỜI KÊ KHAI</p>
        <i>(Ký, ghi rõ họ tên)</i>
      </b-col>
    </b-row>
    <p class="note">
      <b>Chú thích:</b>
      <i>(1) Cơ quan đăng ký cư trú.</i>
      <i>(2) Ghi rõ ràng, cụ thể nội dung đề nghị. Ví dụ: đăng ký thường trú; đăng ký tạm trú; tách hộ; xác nhận thông tin về cư trú…</i>
      <i>(3) Áp dụng đối với các trường hợp quy định tại khoản 2, khoản 3, khoản 4, khoản 5, khoản 6 Điều 20; khoản 1 Điều 25 Luật Cư trú</i>
      <i>(4) Áp dụng đối với trường hợp người chưa thành niên, người hạn chế hành vi dân sự, người không đủ năng lực hành vi dân sự có thay đổi thông tin về cư trú</i>
    </p>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
} from "bootstrap-vue";
import { parseDateToString } from '@/auth/utils';

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { parseDateToString };
  },
};
</script>
<style lang="scss" scope>
  h5 {
    color: #000;
  }

  .wrapper {
    padding: 50px 100px;
    width: 800px;
  }

  .temp {
    white-space: pre-line;
    text-align: center;
    position: absolute;
    top: 40px;
    right: 70px;
    font-size: 12px;
  }

  .box {
    border: 1px solid black;
    border-right: 0;
    padding: 5px 10px;

    &:last-child {
      border-right: 1px solid black;
    }
  }

  table {
    td,th {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
    }
  }

  .sign-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    margin: 20px 5px 0 5px;

    > i {
      height: 25px;
      margin-bottom: 10px;
    }

    i,p {
      text-align: center;
    }

    i{
      font-size: 12px;
    }

    p {
      font-size: 13px;
      height: 90px;
    }
  }

  .note {
    display: flex;
    flex-direction: column;
    margin-top: 200px;

    i {
      font-size: 12px;
    }
  }
</style>
