<template>
  <div>
    <vue-good-table
      class="mb-1 mt-1"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Common -->
        <span v-if="props.column.field === 'type'">
          {{ props.row.type === 'cash' ? 'Tiền mặt' : props.row.type === 'bank' ? 'Ngân hàng' : 'Ví điện tử' }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          {{ t('Không có bản ghi nào!') }}
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BSpinner,

    VueGoodTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: this.data,
    };
  },
  watch: {
    data(val) {
      this.rows = val;
    },
  },
  setup() {
    const { t } = useI18nUtils();
    // Table Handlers
    const columns = [
      {
        label: t('Mã'),
        field: 'code',
        sortable: false,
      },
      {
        label: t('Tên'),
        field: 'name',
        sortable: false,
      },
      {
        label: t('SĐT'),
        field: 'phone',
        sortable: false,
      },
    ];

    return {
      columns,
      t,
    };
  },
};
</script>
